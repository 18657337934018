
.assign-roles-modal{
    width: 642px !important;
}

.upload-signature{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.assign-roles-modal .spin-user{
    display: block;
    position: absolute;
    width: 40px;
    margin-left: 50%;
    margin-top: 5%;
    height: 40px;
    z-index: 99999;
  }