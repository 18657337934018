@import "~antd/dist/antd.css";

.reports-list-container {
  /* border: 1px solid #f0f0f0 !important; */
  height: 100%;
}

.reports-list-container .reports-list-header .ant-card-body {
  padding: 10px;
  background-color: #5a4bb3;
  color: #fff;
  font-size: 13px;
}

.reports-list-container .reports-list-header {
  font-weight: 500;
  margin: -10px 40px 0px;
  font-size: 15px;
  width: 200px;
  /* border-bottom: 1px solid #f0f0f0 !important; */
}

.reports-list-container .reports-list-vertical {
  border-top: 3px solid #5a4bb3;
  margin: 0px 40px 0px 40px;
}

.reports-list-container .reports-list-table {
  margin-left: 40px;
  margin-right: 40px;
}

.reports-list-container .reports-list-header .ant-card-bordered {
  border: 0 !important;
}

.reports-list-container #reports-list-spin {
  position: fixed;
  width: 100%;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  display: block;
  z-index: 9999;
}

.reports-list-container #reports-list-spin .reports-list-spin-user {
  position: absolute;
  left: 0;
  right: 0;
}

.reports-list-container .table-row-light {
  background-color: #ffffff;
}
.reports-list-container .table-row-dark {
  background-color: #f0f2f8;
}

.reports-list-container thead[class*="ant-table-thead"] th {
  background-color: #f0f2f8 !important;
}

.reports-list-container .spin-user {
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 50%;
  margin-top: 35%;
  height: 40px;
  z-index: 99999;
}
.reports-list-container .ant-select-multiple{
  max-height: 60px;
  overflow-y: auto;
}