form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  background-color: #fff;
  /* box-shadow: 0px 0px 14px 14px #dfe8f0; */
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
}

.sign-in-container {
  right: 0;
  width: 50%;
  z-index: 2;
}

.overlay-container {
  top: 0;
  right: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  background: url("../../assets/left-bg.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.overlay {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  width: 50%;
  height: 100%;
  /* margin-top: 40px; */
}

.overlay-right {
  right: 0;
}

.form-container-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 100%;
}

.form-container-panel .ant-form {
  width: 100%;
}

.form-container-panel .ant-form-item {
  width: 100%;
}

.form-container-panel .input-credentials {
  height: 45px;
  border: none;
  background-color: #f7f7f7;
}

.form-container-panel .input-credentials-container-username,
.form-container-panel .input-credentials-container-password {
  border-left: 6px solid #007af5;
}

.form-container-panel .input-credentials-container-username {
  margin-top: 30px;
}

.form-container-panel .login-account-header {
  width: 100%;
  font-size: 21px;
  color: #6154a4;
  text-align: start;
  font-weight: bold;
}

.form-container-panel .forgot-account-header {
  width: 100%;
  font-size: 18px;
  color: #6154a4;
  text-align: start;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-container-panel .login-account-sub-header {
  width: 100%;
  text-align: start;
  color: #77767e;
}

.form-container-panel .forgot-account-sub-header {
  margin-bottom: 20px;
  width: 100%;
  text-align: start;
  color: #77767e;
}

.form-container-panel .ant-form-item-explain.ant-form-item-explain-error {
  text-align: start;
  margin-left: 5px;
}

.form-container-panel .ant-form-item-control-input {
  text-align: start;
}

.form-container-panel .ant-checkbox + span {
  font-weight: 100;
  color: #b9c8de;
  font-style: normal;
  font-size: 14px;
  padding-right: 30px !important;
}

.container .form-container-panel .login-form-forgot {
  color: #2f82ff;
}

.form-container-panel .ant-btn-primary {
  background-color: #6e5ec0 !important;
  border-color: #6e5ec0 !important;
  width: 100%;
  margin-top: 20px;
}

.overlay-container .overlay .logo {
  /* height: 150px; */
  width: 150px;
}

.overlay-container .overlay .overlay-title {
  color: #fff;
  margin-top: 20px;
  font-weight: 800;
  font-size: 20px;
  text-shadow: 0px 1px 0px #000;
  font-family: "Times New Roman", Times, serif;
}

.overlay-container .overlay .overlay-sub-title {
  margin-top: 15px;
  text-align: start;
}

.container .form-container-panel .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
  border-color: #b9c8de;
}

.container
  .form-container-panel
  .ant-checkbox-checked
  .ant-checkbox-inner:after {
  border-color: #007af5 !important;
}

.container .spin-user {
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 50%;
  margin-top: 30%;
  height: 40px;
  z-index: 99999;
}

.form-container-panel .ant-btn:hover,
.form-container-panel .ant-btn:focus {
  color: white;
}

.forgot-password-sent-container .tick-icon{
  height: 64px;
}

.forgot-password-sent-container .confirmation-subtext{
  margin-top: 16px;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 22px;
  color: black;
  text-align: center;
}

.forgot-password-sent-container .confirmation-text{
  margin-top: 32px;
  color: #000;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
}

.forgot-password-sent-container .form{
  background-color: transparent !important;
}

.forgot-password-sent-container .form-item{
  text-align: center;
}