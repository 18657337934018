.dashboard-search-container {
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: repeat(3, 1fr);
  height: 200px;
  background-color: #f2f0f8;
  margin: 0px 40px 5px 40px;
  padding: 5px 30px;
}

/* Reports */
.reports-search-container {
  display: grid;
  grid-column-gap: 50px;
  grid-template-columns: repeat(3, 1fr);
  height: 200px;
  background-color: #f2f0f8;
  margin: 0px 40px 5px 40px;
  padding: 5px 30px;
}

.dashboard-search-container .dashboard-search,
.reports-search-container .reports-search {
  display: grid;
  grid-template-columns: 98px auto;
  align-self: center;
}

.dashboard-search-container .dashboard-text,
.reports-search-container .reports-text {
  align-self: center;
  padding-right: 10px;
  font-weight: 500;
}

.dashboard-search-container .dashboard-input,
.reports-search-container .reports-input {
  height: 35px;
  position: relative;
  min-width: 250px;
}

.dashboard-search-container .dashboard-search .search,
.reports-search-container .reports-search .search {
  margin-right: 25px;
  height: 40px;
  border-radius: 10px;
  background-color: #01cab8;
  color: #fff;
}

.dashboard-search-container .dashboard-search .dashboard-rangepicker,
.reports-search-container .reports-search .reports-rangepicker {
  height: 35px;
  align-self: center;
}

.dashboard-search-container .button {
  grid-column: 3;
  display: grid;
  grid-template-columns: auto auto auto;
}

.reports-search-container .button {
  grid-column: 3;
  display: grid;
  grid-template-columns: 110px 110px;
  justify-content: flex-end;
}
.repotssearch{
  grid-template-columns: 154px 110px 110px !important;
}
.dashboard-search-container .ant-select-selector,
.reports-search-container .ant-select-selector {
  max-height: 60px;
  overflow-y: auto;
}

.dashboard-search-container .ant-select-show-search,
.reports-search-container .ant-select-show-search {
  align-self: center;
}

.dashboard-search-container
  .ant-select-selection-item[title="Select Certificate Type"],
.reports-search-container
  .ant-select-selection-item[title="Select Certificate Type"] {
  color: #bfbfbf;
}

.reports-search-container .reports-search .search:active,
.reports-search-container .reports-search .search:hover {
  border: inherit;
}

.reports-search-container .ant-select-selection-item[title="Select a Course"],
.reports-search-container
  .ant-select-selection-item[title="Select requisitioner name"],
.reports-search-container
  .ant-select-selection-item[title="Select Certificate Status"],
.dashboard-search-container .ant-select-selection-item[title="Select a Course"],
.dashboard-search-container
  .ant-select-selection-item[title="Select requisitioner name"] {
  color: #bfbfbf;
}

.assign-roles-modal .spin-user:before {
  border-radius: 6px;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  content: " ";
}

/* Media Queries */
@media screen and (max-width: 1260px) {
  .dashboard-search-container,
  .reports-search-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-search-container .button,
  .reports-search-container .button {
    grid-column: 2;
  }
  .ant-table-container {
    overflow-x: auto;
  }
}

@media screen and (max-width: 850px) {
  .dashboard-search-container {
    grid-template-columns: repeat(1, 1fr);
    height: 310px;
  }

  .reports-search-container{
    grid-template-columns: repeat(1, 1fr);
    height: 400px;
  }

  .dashboard-search-container .button,
  .reports-search-container .button {
    grid-column: 1;
  }
  .ant-table-container {
    overflow-x: auto;
  }
}
