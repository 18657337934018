.user-profile-container .ant-card-body {
  padding: 16px;
}

.user-profile-header {
  font-weight: 500;
  margin: -10px 40px 0px;
  font-size: 15px;
  width: 200px;
}

.user-profile-header .ant-card-body {
  padding: 10px;
  background-color: #5a4bb3;
  color: #fff;
  font-size: 13px;
}
.user-profile-container .user-profile-divider {
  border-top: 3px solid #5a4bb3;
  margin: 0px 40px 0px 40px;
}

.user-profile-container .user-profile-sub-divider {
  border-top: 3px solid #5a4bb3;
  margin: 40px 40px 0px 40px;
}

.user-profile-container .user-profile-switch {
  grid-template-columns: auto auto;
  display: grid;
  align-items: center;
  height: 60px;
  background-color: #f2f0f8;
  margin: -80px 40px 0px 205px;
}

.user-profile-container .user-profile-switch-name {
  font-size: 15px;
  font-weight: 500;
  display: grid;
  margin-right: 15px;
  grid-template-columns: 50% 75px;
}

.user-profile-container .ant-switch-checked {
  background-color: #5a4bb3;
}

.user-profile-container .update-button {
  margin: 20px 0px 0px 60px;
  background-color: #01cab8;
  color: black;
  min-width: 100px;
  visibility: hidden;
  border-radius: 10px;
}

.user-profile-container .user-back-button {
  margin: 20px 0px 0px 15px;
  background-color: #ff8c59;
  color: black;
  min-width: 100px;
  visibility: hidden;
  border-radius: 10px;
}

.user-profile-container .spin-user {
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 50%;
  margin-top: 10%;
  height: 40px;
  z-index: 99999;
}

.user-profile-container .user-profile-update-container .ant-btn:hover,
.user-profile-container .user-profile-update-container .ant-btn:focus {
  border-color: #0dd398 !important;
}

.cancel-popup-spin .spin-user{
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 50%;
  margin-top: 5%;
  height: 40px;
  z-index: 99999;
}