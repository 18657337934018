.template-container .template-header .ant-card-body {
  padding: 10px;
  background-color: #5a4bb3;
  color: #fff;
  font-size: 13px;
}

.template-container .template-header {
  font-weight: 500;
  margin: -10px 40px 0px;
  font-size: 15px;
  width: 200px;
}

.template-container .template-vertical {
  border-top: 3px solid #5a4bb3;
  margin: 0px 40px 0px 40px;
}

.template-container .template-button {
  margin: 10px 40px 0px 40px;
  text-align: end;
}

.template-container .ant-table {
  /* margin: 0px 40px; */
  margin: 25px 40px;
}

.template-container .ant-table-pagination.ant-pagination {
  margin: 16px 40px;
}

.template-container .spin-user {
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 50%;
  margin-top: 15%;
  height: 40px;
  z-index: 99999;
}

.template-container
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.template-container
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:nth-child(2) {
  text-align: start;
}

.template-container .ant-table-container table > thead > tr:first-child th {
  text-align: center;
}

.template-container .ant-table-tbody > tr > td:first-child,
.template-container .ant-table-tbody > tr > td:nth-child(2) {
  text-align: start;
}

.template-container .ant-table-tbody > tr > td {
  text-align: center;
}

.template-container .ant-btn:hover,
.template-container .ant-btn:focus {
  color: #fff !important;
  border-color: inherit !important;
}
