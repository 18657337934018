@import "~antd/dist/antd.css";

.settings-container .settings-header .ant-card-body,
.signature-container .settings-header .ant-card-body {
  padding: 10px;
  background-color: #5a4bb3;
  color: #fff;
  font-size: 13px;
}

.main-settings-outside-continer {
  margin-top: 150px;
}

.settings-container .settings-header ,
.signature-container .settings-header{
  font-weight: 500;
  margin: -10px 40px 0px;
  font-size: 15px;
  width: 200px;
}

.settings-container .settings-vertical ,
.signature-container .settings-vertical{
  border-top: 3px solid #5a4bb3;
  margin: 0px 40px 0px 40px;
}

.settings-container .settings-sub-header {
  display: flex;
  padding: 10px 0px;
  float: right;
  margin-right: 40px;
}

.settings-container .settings-header .ant-card-bordered,
.signature-container .settings-header .ant-card-bordered  {
  border: 0 !important;
}

.settings-container .ant-table {
  margin: 0px 40px;
}

.settings-sub-header .ant-table {
  padding-bottom: 25px;
}

.settings-container .ant-table-pagination.ant-pagination {
  margin: 16px 40px;
}

.settings-container .table-row-light {
  background-color: #ffffff;
}
.settings-container .table-row-dark {
  background-color: #f0f2f8;
}

.settings-container .spin-user {
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 50%;
  margin-top: 15%;
  height: 40px;
  z-index: 99999;
}

.signature-container .spin-user {
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 50%;
  margin-top: 25%;
  height: 40px;
  z-index: 99999;
}

.settings-container .settings-sub-header .ant-btn:hover,
.settings-container .settings-sub-header .ant-btn:focus {
  color: black;
  border-color: #ff8c59 !important;
}

.configuration-settings-container .configuration-settings-wrapper {
  display: grid;
  grid-template-columns: 400px auto;
  margin: 20px 40px;
  min-height: 200px;
  /* background-color:  #f2f0f8; */
}

.configuration-settings-container .configuration-settings-divider {
  border-top: 3px solid #5a4bb3;
  margin: 0px 40px 0px 40px;
}

.configuration-settings-wrapper div:nth-child(3),
.configuration-settings-wrapper div:nth-child(4),
.configuration-settings-wrapper div:nth-child(7),
.configuration-settings-wrapper div:nth-child(8),
.configuration-settings-wrapper div:nth-child(11),
.configuration-settings-wrapper div:nth-child(12),
.configuration-settings-wrapper div:nth-child(15),
.configuration-settings-wrapper div:nth-child(16) {
  background-color: #fff;
}

.configuration-settings-wrapper .configuration-settings-input {
  padding: 10px 0px;
  background-color: #f2f0f8;
}

.configuration-settings-wrapper .configuration-settings-header {
  align-self: center;
  padding: 15px 15px;
  background-color: #f2f0f8;
}

.company-settings-wrapper {
  display: grid;
  grid-template-columns: 350px auto;
  margin: 25px 40px;
  min-height: 300px;
}

.configuration-settings-container .spin-user {
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 50%;
  margin-top: 15%;
  height: 40px;
  z-index: 99999;
}

.company-profile-container .spin-user {
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 50%;
  margin-top: 30%;
  height: 40px;
  z-index: 99999;
}

.company-settings-wrapper .display-style {
  margin-bottom: 15px;
}

.company-profile-container .submit-button {
  margin-left: 40px;
  min-width: 100px;
  visibility: hidden;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 50px;
  color: #fff;
}

.company-logo-container {
  display: grid;
  grid-template-columns: 130px auto;
}
.company-profile-container .upload-container {
  border: 1px solid grey;
  margin-bottom: 15px;
  padding: 15px;
  width: 100px;
  text-align: center;
  cursor: pointer;
}

.ant-upload-list-text {
  display: none;
}

.signature-container .signature-container {
  padding: 20px;
  display: grid;
  grid-template-columns: auto 300px;
}

.settings-container .sign-container {
  padding: 20px;
}

.signature-container .signature-icon {
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}
.signature-container .signature-header {
  font-size: 15px;
  margin-top: 10px;
  align-self: center;
  text-align: center;
  font-weight: bold;
}
.signature-container .signature-sub-container {
  display: grid;
  justify-content: center;
  text-align: center;
  padding-bottom: 50px;
}

.signature-container .settings-radio-container {
  border: 1px solid #d3d3d3;
  margin-top: 20px;
  padding: 20px 100px 20px 100px;
  display: grid;
}

.submit-signature,
.submit-signature:hover,
.submit-signature:active,
.submit-signature:focus {
  height: 40px;
  width: 150px;
  cursor: pointer;
  background-color: #57c059;
  color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  border-color:unset;
}
.submit-preview,
.submit-preview:hover,
.submit-preview:active,
.submit-preview:focus {
  height: 40px;
  width: 150px;
  cursor: pointer;
  background-color: #ff8c59;
  color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 10px;
  border-color:unset;
}

.signature-container .settings-radio-container .signature-header {
  font-weight: bold;
  font-style: italic;
  color: red;
}

.signature-container .settings-radio-container .upload {
  background: #ff8c59;
  color: #fff;
}

.signature-container .settings-radio-container .upload:hover,
.signature-container .settings-radio-container .upload:active,
.signature-container .settings-radio-container .upload:focus {
  border: inherit;
}

.clear,
.clear:hover,
.clear:active,
.clear:focus {
  background: #ff8c59;
  width: 150px;
  color: #fff;
  height: 40px;
  border-radius: 10px;
  margin-left: 15px;
}

.signature-container .settings-radio-container .image-uploaded {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.company-profile-container .user-profile-divider {
  border-top: 3px solid #5a4bb3;
  margin: 0px 40px 0px 40px;
}

.company-profile-container .user-profile-sub-divider {
  border-top: 3px solid #5a4bb3;
  margin: 40px 40px 0px 40px;
}
.csv-upload-preview {
  border: 1px solid #d9d9d9;
    padding: 19px;
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.csv-upload-button{
  padding: 8px 13px;
    background-color: rgb(255, 140, 89);
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.csv-upload-spinner {
  display: block;
  position: absolute;
  width: 40px;
  margin-left: 48%;
  margin-top: 25%;
  height: 40px;
  z-index: 99999;
}