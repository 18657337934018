canvas {
  max-width: 600px !important;
  max-height: 300px !important;
}

.banner-wrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 200px;
  background-color: #d1ecf3;
  margin-bottom: 50px;
  margin-top: 20px;
}

.banner-wrapper .banner-warpper-card {
  display: table-cell;
  height: 100px;
  vertical-align: middle;
}

.banner-wrapper .banner-wrapper-sub-card {
  padding: 24px;
  width: 150px;
  margin: auto;
  background: #fff;
  text-align: center;
  border-radius: 30px;
}

.banner-wrapper .banner-wrapper-sub-card-header-value {
  color: #01cab8;
  font-size: 35px;
  font-weight: 700;
}

.banner-wrapper .banner-wrapper-sub-card-header {
  color: #5d67a8;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 22px;
}

.dashboard-table-container thead[class*="ant-table-thead"] th {
  background-color: #f0f2f8 !important;
  font-size: 14px;
  text-align: center;
}

.dashboard-table-container .ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #e4e3e3;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.dashboard-table-container .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fafafa !important;
  text-align: center;
  background: aqua;
}

.dashboard-table-container .ant-table-tbody > tr.ant-table-row > td {
  text-align: center;
}

.dashboard-table-container {
  overflow: auto;
}

.dashboard-table-container.ant-table table {
  width: 100%;
  text-align: center;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 16px;
  font-weight: 600;
}

.dashboard-table-container .ant-table-pagination.ant-pagination {
  display: none;
}

.dashboard-table-container .ant-table {
  border: 1px solid #e4e3e3;
}

.dashboard-section-conatiner {
  border: 1px solid #d3d3d3;
  /* padding: 10px; */
  margin: 0px 20px;
  /* background-color: #d1ecf3; */
}
.dashboard-section-conatiner .dashboard-section-time {
  border-bottom: 1px solid #d3d3d3;
  padding: 10px;
  background: aliceblue;
}

.dashboard-table-container .table-header{
  text-align: center;
  font-weight: 500;
  background-color: #f0f2f8 !important;
  padding: 15px;
}

.dashboard-table-container .ant-table-title{
  padding: 0px !important
}