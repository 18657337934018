.main-container {
  overflow: auto;
  height: 100vh;
  width: 100vw;
}

.main-container .main-container-bg::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  content: "";
  z-index: -1;
  background: url("../assets/login-bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.main-container .main-container-bg .main-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
}
